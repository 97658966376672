<template>
  <li
    class="user-info__info-list__item"
  >
    <span class="user-info__info-list__item__title">
      {{ field.label }} <span
        v-if="field.required"
        style="color: red;"
      >*</span>
    </span>
    <span
      class="user-info__info-list__item__field"
    >
      <!--TODO ИЗМЕНИТЬ НА SPAN-->
      <component
        :is="field.type"
        v-if="!isEdit || !field.canEdit"
        input-class="user-card__field"
        disabled
        :values="[]"
        :visualValue="field.type.name === 'AppSelect' ? copyData[field.model][field.visualValue] : ''"
        :placeholder="userData ? setValue(userData[field.value], field) : $t('loader.wait_load')"
      />
      <component
        :is="field.type"
        v-else
        v-model="copyData[field.model]"
        input-class="user-card__field"
        :placeholder="userData ? setValue(userData[field.value], field) : $t('loader.wait_load')"
        :validationInput="field.validator"
        :validation-list="field.validationList"
        :values="selectedList[field.values]"
        :itemValue="field.itemValue"
        :optionKey="field.optionKey"
        :visualValue="field.visualValue ? copyData[field.model][field.visualValue] : null"
        show-validation-message
      >
        <template #default="{value: office}">
          {{ office.title }}
        </template>
      </component>
    </span>
  </li>
</template>

<script>
import AppInput from '@/components/common/simple/AppInput'
import userUtil from '@/utils/user-util'

export default {
  name: 'UserInfoItem',
  components: { AppInput },
  props: {
    field: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: true,
    },
    copyData: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    officeList () {
      return this.$store.state.office.items
    },
    selectedList () {
      return {
        objects: this.officeList,
        roleList: userUtil.getRoleList(),
      }
    },
  },
  methods: {
    setValue (value, field) {
      let valueResult = value
      if (value instanceof Object) {
        valueResult = valueResult[field.subProp]
      }
      if (!valueResult) return this.$t('empty')
      return valueResult
    },
  }
}
</script>

<style lang="sass" scoped>
  $marginColumnValue: 40px
  .user-info
    &__header
      display: flex
      margin-bottom: 40px

      &__action
        @extend %info-text
        font-weight: 800
        margin-left: auto
    &__info-list__item
      display: flex
      align-items: center
      width: 100%
      margin: 10px 0
      &__title
        @extend %title-text
        width: 30%
        text-align: right
      &__value
        @extend %value-text
        width: 70%
        margin-left: $marginColumnValue
      &__field
        display: flex
        width: 70%
        margin-left: $marginColumnValue
</style>
