<template>
  <div class="wb-section">
    <h3 class="wb-section__title">
      <slot name="headerText" />
    </h3>
    <div class="wb-section__action">
      <slot name="leftGroup" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppSectionHeader'
}
</script>

<style lang="sass" scoped>
  .wb-section
    display: flex
    margin-bottom: 40px
    &__action
      @extend %info-text
      position: relative
      font-weight: 800
      margin-left: auto


</style>

