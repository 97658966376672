<template>
  <div
    v-if="isLoading"
    class="user-info__action-panel"
  >
    <AppLoader />
  </div>
  <div v-else>
    <AppSectionHeader>
      <template #leftGroup>
        <div
          v-if="!isEdit"
          class="user-info__action-panel"
          @click="toggleEdit"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                d="M4.37585 0L15.1248 10.7489L10.749 15.1247L0 4.3758L4.37585 0Z"
                fill="black"
              />
              <path
                d="M16.0001 12V16H12.0001L16.0001 12Z"
                fill="black"
              />
            </g>
          </svg>
          {{ $t('user_card.personal.header.actions.edit') }}
        </div>
        <div
          v-else
          class="user-info__action-panel action-list-wrapper"
        >
          <p
            class="action-list__action"
            @click="saveNewInfo"
          >
            {{ $t('user_card.personal.header.actions.save') }}
          </p>
          <p
            class="action-list__action"
            @click="toggleEdit"
          >
            {{ $t('user_card.personal.header.actions.cancel') }}
          </p>
        </div>
        <div
          v-if="userInfo && !isEditCurrentUser && !isLoading && !isEdit"
          style="text-align: right; cursor: pointer; position: absolute; right: 0;"
          :style="isEdit ? ' top: 80px' : ' top: 40px'"
        >
          <!--TODO ПОправить верстку!-->
          <p
            v-if="userInfo.status !== 'blocked'"
            style="margin-bottom: 10px; cursor: pointer"
            @click="fetchBlockUser"
          >
            Заблокировать
          </p>
          <p
            v-else
            style="margin-bottom: 10px; cursor: pointer ;color: rgba(237,70,47,0.81)"
            @click="fetchBlockUser"
          >
            Разблокировать
          </p>
          <!--          <p
            style="color: rgba(237,70,47,0.81);cursor: pointer"
            @click="fetchRemoveUser"
          >
            Удалить
          </p>-->
        </div>
      </template>
    </AppSectionHeader>
    <div
      v-for="(fields, key) in personValue"
      :key="key"
      class="user-info__info-list"
    >
      <ul v-if="userInfo && copyUserInfo">
        <UserInfoItem
          v-for="field in fields"
          :key="field.value"
          :field="field"
          :userData="userInfo"
          :copyData="copyUserInfo"
          :isEdit="isEdit"
          :required="field.required"
        />
      </ul>
      <SpliceLine v-if="key !== 'role'" />
    </div>
    <div style="width: 80%; margin: auto">
      <OfficeMember
        v-if="canEditOffice && userInfo && userInfo.id"
        style="margin-bottom: 16px"
        :editable="isEdit"
        :office-list="officeList"
        :multiple="hasMultipleOffice"
        :selected-office-list.sync="selectedOfficeList"
      />
    </div>
    <div
      v-if="isEdit"
      class="user-info__action-panel action-list-wrapper"
    >
      <p
        class="action-list__action"
        @click="saveNewInfo"
      >
        {{ $t('user_card.personal.header.actions.save') }}
      </p>
      <p
        class="action-list__action"
        @click="toggleEdit"
      >
        {{ $t('user_card.personal.header.actions.cancel') }}
      </p>
    </div>
  </div>
</template>

<script>
import SpliceLine from '@/components/common/special/SpliceLine'
import AppSectionHeader from '@/components/common/AppSectionHeader'
import UserInfoItem from '@/components/users/UserInfoItem'
import { allNumbers, maxLength, ruPhoneValidator } from '@/utils/validation'
import AppInput from '@/components/common/simple/AppInput'
import AppSelect from '@/components/common/simple/AppSelect'
import userUtil from '@/utils/user-util'
import routeList from '@/router/labels'
import AppLoader from '@/components/AppLoader'
import OfficeMember from '@/components/common/office-member'

export default {
  name: 'UserInfoList',
  components: { UserInfoItem, AppSectionHeader, SpliceLine, AppLoader, OfficeMember },
  props: {
    isEditCurrentUser: {
      type: Boolean,
      default: false
    },
    userInfo: {
      type: Object,
      required: true,
      default: () => []
    },
    editList: {
      type: Object,
      default: () => ({
        middleName: true,
        lastName: true,
        firstName: true,
        profession: true,
        email: false,
        telephone: true,
        roleList: false,
        office: false,
      })
    }
  },
  data () {
    return {
      isEdit: false,
      selectedOfficeList: [],
      isLoading: false,
      copyUserInfo: null,
      personValue: {}
    }
  },
  computed: {
    officeList () {
      return this.$store.state.office.items
    },
    currentUser () {
      return this.$store.state.users.info
    },
    // copyUserInfo () {
    //   const user = this.$store.state.users.info
    //   return { ...user, office: user.office || {} }
    // },
    isRoot () {
      return this.currentUser && this.currentUser.id && userUtil.isRoot(this.currentUser)
    },
    hasMultipleOffice () {
      if (!this.userInfo || !this.userInfo.id) {
        return false
      }
      return userUtil.isRoot(this.userInfo) || userUtil.isAdmin(this.userInfo)
    },
    canEditOffice () {
      return this.currentUser && this.currentUser.id && (
        userUtil.isRoot(this.currentUser) || userUtil.isAdmin(this.currentUser)
      )
    },
    isUserCard () {
      return this.$route.name === routeList.USER_CARD_NAME
    },
  },
  watch: {
    userInfo (value) {
      this.copyUserInfo = { ...value, office: value.office || {} }
    }
  },
  created () {
    this.initUserInfo()
    this.copyUserInfo = { ...this.userInfo, office: this.userInfo ? this.userInfo.office : {} }
  },
  beforeDestroy () {
    this.$store.commit('users/setEditedUser', null)
  },
  methods: {
    initUserInfo () {
      if (this.userInfo.officeList.length) {
        this.selectedOfficeList = this.userInfo.officeList.map((item) => ({ id: item.id }))
      }

      this.personValue = {
        userParam: [
          {
            label: this.$t('field_placeholder.lastName'), value: 'lastName',
            model: 'lastName',
            canEdit: this.editList.lastName,
            type: AppInput,
            required: true
          },
          {
            label: this.$t('field_placeholder.firstName'), value: 'firstName', model: 'firstName',
            canEdit: this.editList.firstName,
            type: AppInput,
            required: true
          },
          {
            label: this.$t('field_placeholder.middleName'), value: 'middleName', model: 'middleName',
            canEdit: this.editList.middleName,
            type: AppInput,
            required: true
          },
        ],
        position: [
          {
            label: this.$t('field_placeholder.profession'), value: 'profession', model: 'profession',
            canEdit: this.editList.profession,
            type: AppInput,
            required: true
          },
        ],
        contacts: [
          {
            label: this.$t('field_placeholder.email'), value: 'email', model: 'email',
            canEdit: this.editList.email,
            type: AppInput
          },
          {
            label: this.$t('field_placeholder.telephone'), value: 'telephone', model: 'telephone',
            canEdit: this.editList.telephone,
            validator (value) {return maxLength(value, 11) && allNumbers(value)},
            validationList: [
              {
                text: `${this.$t('validations.phone')} (79ХХХХХХХХХ)`,
                valid: true, validator: (value) => ruPhoneValidator(value)
              },
            ],
            type: AppInput,
            required: true
          },
        ],
        role: [
          {
            label: this.$t('field_placeholder.roleList'),
            value: 'role',
            model: 'role',
            canEdit: this.editList.roleList,
            type: AppSelect,
            values: 'roleList',
            itemValue: 'title',
            optionKey: 'title',
            visualValue: 'title'
          },
          // {
          //   label: this.$t('field_placeholder.roleList'), value: 'roleList', model: 'roleList',
          //   canEdit: this.editList.roleList,
          //   type: AppInput
          // },
        ]
      }


      if (!this.isRoot || this.$route.name !== routeList.USER_CARD_NAME) {
        // this.personValue.role.push(
        //   {
        //     label: this.$t('field_placeholder.office'),
        //     value: 'office',
        //     model: 'office', canEdit: this.editList.office,
        //     type: AppSelect,
        //     values: 'objects',
        //     itemValue: 'title',
        //     optionKey: 'title',
        //     visualValue: 'title'
        //   },
        // )
      }
    },
    toggleEdit () {
      this.isEdit = !this.isEdit

      if (!this.isEdit) {
        this.$emit('reloadUser')
      }
    },
    saveNewInfo () {
      this.isLoading = true
      this.$emit('onPageRequesting', true)
      try {
        const method = this.$route.name === routeList.USER_CARD_NAME ? 'fetchUpdateUserData' : 'updateUser'
        this.$store.dispatch(`users/${method}`, {
          userData: {
            ...this.copyUserInfo,
            officeList: this.selectedOfficeList
          }
        })
          .then(() => {
            this.$store.commit('users/setEditedUser', null)
            if (this.$route.name === routeList.USER_CARD_NAME) {
              return this.$store.dispatch('users/fetchUserData')
            } else {
              this.$emit('reloadUser')
            }
          })
          .then(() => {
            this.isEdit = false
            this.isLoading = false
            this.$emit('onPageRequesting', false)
          })
      } catch (e) {
        this.isLoading = false
        this.$emit('onPageRequesting', false)
      }
    },
    fetchBlockUser () {
      this.$emit('blockUser', this.copyUserInfo)
    },
    fetchRemoveUser () {
      this.$emit('removeUser', this.copyUserInfo)
    }
  }
}
</script>

<style lang="sass" scoped>
  .action-list-wrapper
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: flex-end
  .action-list
    text-align: right
    &__action
      margin-right: 40px
      font-size: 16px
      line-height: 20px
      font-weight: 800
      opacity: 0.6
      cursor: pointer
  .user-info__action-panel
    cursor: pointer
</style>
