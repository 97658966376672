<template>
  <div class="wb-line" />
</template>

<script>
export default {
  name: 'SpliceLine'
}
</script>

<style lang="sass" scoped>
.wb-line
  width: 100%
  height: 1px
  margin: 25px 0
  background-color: $line-color_gray
</style>
